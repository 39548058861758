const toggleButtons = document.querySelectorAll('.screen-toggle');
const question1choices = document.getElementsByName('question1');
const question1Next = document.querySelector("#question1next");
const question2choices = document.getElementsByName('question2');
const question2Next = document.querySelector("#question2next");
const question2yes = document.querySelector("#question2yes");
const question2no = document.querySelector("#question2no");
const question3lower = document.querySelector("#question3lower");
const question3spread = document.querySelector("#question3spread");
const question3choices = document.getElementsByName('question3');
const question3Next = document.querySelector("#question3next");

// Initial Tracking
const targetOrigin = "https://www.bmw-motorrad.co.uk/en/finance/finance-decision-tool.html";
window.top.postMessage('Start', targetOrigin);

// Function to change screen
function showScreen(target) {
    let targetElem = document.querySelector(
        '.screen[data-screen=\'' + target + '\']');
    
    if (targetElem) {
        // Reset all other screens
        const screens = document.querySelectorAll('.screen');
        screens.forEach(screen => {
            screen.classList.remove('active');
        });
        
        targetElem.classList.add('active');
        
        // Reset videos
        const videos = document.getElementsByTagName('video');
        for (let video of videos) {
            video.pause();
            video.currentTime = 0;
        }
    }
}

// Detect clicks on screen toggles and get target
document.body.addEventListener('click', function (e) {
    // But only alert for elements that have an alert-button class
    if (e.target.classList.contains('screen-toggle')) {
        let target = e.target.getAttribute('data-screen');
        let direction = e.target.textContent;
        showScreen(target);
        if(target) {
            // console.log([direction, target, window.answers]);
            window.top.postMessage([direction, target, window.answers], targetOrigin);
        }
    }
    
    // reset all radio buttons on restart
    if (e.target.classList.contains('restart')) {
        window.answers = [];
        question1choices.forEach((e) => {
            e.checked = false;
        });
        question2choices.forEach((e) => {
            e.checked = false;
        });
        question3choices.forEach((e) => {
            e.checked = false;
        });
    }
});

window.answers = [];

/**
 * Question 1
 */

window.answers[1] = false;

question1choices.forEach(choice => choice.addEventListener('change', e => {
    // Store Question Answer
    window.answers[1] = e.target.value;
    
    // Get Next Screen Target
    const next = e.target.getAttribute('data-target');
    
    // Set Next Target based on answer
    question1Next.setAttribute('data-screen', next);
}));

question1Next.addEventListener('click', e => {
    if (window.answers[1] == 'own') {
        question2yes.setAttribute('data-target', 'bmw-select');
        question2no.setAttribute('data-target', 'question3');
    } else if (window.answers[1] == 'notsure') {
        question2yes.setAttribute('data-target', 'question3');
        question2no.setAttribute('data-target', 'question3');
    } else {
        question2yes.setAttribute('data-target', 'question1');
        question2no.setAttribute('data-target', 'question1');
    }
});


/**
 * Question 2
 */

window.answers[2] = false;
question2choices.forEach(choice => choice.addEventListener('change', e => {
    // Store Question Answer
    window.answers[2] = e.target.value;
    
    // Get Next Screen Target
    const next = e.target.getAttribute('data-target');
    
    // Set Next Target based on answer
    question2Next.setAttribute('data-screen', next);
}));

question2Next.addEventListener('click', e => {
    if (window.answers[1] == 'own' && window.answers[2] == 'yes') {
        question3lower.setAttribute('data-target', 'bmw-select');
        question3spread.setAttribute('data-target', 'bmw-select');
    } else if (window.answers[1] == 'own' && window.answers[2] == 'no') {
        question3lower.setAttribute('data-target', 'bmw-select');
        question3spread.setAttribute('data-target', 'bmw-hire-purchase');
    } else if (window.answers[1] == 'notsure' && window.answers[2] == 'yes') {
        question3lower.setAttribute('data-target', 'bmw-select');
        question3spread.setAttribute('data-target', 'bmw-contract-hire');
    } else if (window.answers[1] == 'notsure' && window.answers[2] == 'no') {
        question3lower.setAttribute('data-target', 'bmw-select');
        question3spread.setAttribute('data-target', 'bmw-hire-purchase');
    }
});

/**
 * Question 3
 */
window.answers[3] = false;
question3choices.forEach(choice => choice.addEventListener('change', e => {
    // Store Question Answer
    window.answers[3] = e.target.value;
    
    // Get Next Screen Target
    const next = e.target.getAttribute('data-target');
    
    // Set Next Target based on answer
    question3Next.setAttribute('data-screen', next);
}));

// Compare button
const compareButton = document.querySelectorAll('.screen-toggle[data-screen="compare"]');
compareButton.forEach(button => button.addEventListener('click', e => {
    let back = e.target.getAttribute('data-back');
    document.querySelector("#compare-back").setAttribute('data-screen', back);
}));

// Why BMW link
const whyBmwLink = document.getElementById("why-bmw-link");
whyBmwLink.addEventListener("click", function() {
    window.top.postMessage('Why BMW FS', targetOrigin);
});
